<script setup>

</script>

<template>
  <!-- About Start -->
  <div class="container-fluid py-5 my-5">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
          <div class="h-100 position-relative">
            <img src="/img/about2.webp" class="img-fluid w-75 rounded" alt="" style="margin-bottom: 25%;">
            <div class="position-absolute w-75" style="top: 25%; left: 25%;">
              <img src="/img/about1.webp" class="img-fluid w-100 rounded" alt="">
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
          <h5 class="text-muted">About Us</h5>
          <h1 class="mb-4">Quality & Experience IT Solution Company</h1>
          <p>
            We put together a bunch of passionate minds to transform your visions
            into reality imparting an awesome digital experience for your
            users. From website design, development, scalable mobile apps to SaaS platforms,
            the solution we engineer accelerate efficiency and fuel growth for our clients.
          </p>
          <p class="mb-4">
            We are committed to achieving and pushing the level of
            performance at all times. Leveraging the power of technology,
            we deliver end-to-end solutions for multi-million funded
            start-ups to small and medium enterprises. Our office presence at
            Ejisu-Fumesua, GPS Address: AK-218-3548, Ghana.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->
</template>

<style scoped>

</style>