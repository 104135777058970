<script setup>

</script>

<template>
  <!-- Fact Start -->
  <div class="container-fluid py-5" style="background: rgba(204,204,204,0.61);">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
          <div class="d-flex counter">
            <h1 class="me-3 text-primary">&#128142;</h1>
            <h5 class="mt-1">We've got an eye for functional design and impactful content.</h5>
          </div>
        </div>
        <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
          <div class="d-flex counter">
            <h1 class="me-3 text-primary">&#128142;</h1>
            <h5 class="mt-1">We don’t assume. We arrive at solutions through careful inferences.</h5>
          </div>
        </div>
        <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
          <div class="d-flex counter">
            <h1 class="me-3 text-primary">&#128142;</h1>
            <h5 class="mt-1">Clients are friends. Each of their problems are ours.</h5>
          </div>
        </div>
        <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="d-flex counter">
            <h1 class="me-3 text-primary">&#128142;</h1>
            <h5 class="mt-1">Expect design thinking, creativity and problem solving.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fact End -->
</template>

<style scoped>

</style>