<script setup>

</script>

<template>

  <!-- Project Start -->
  <div class="container-fluid project py-5 my-5">
    <div class="container py-5">
      <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style="max-width: 600px;">
        <h5 class="text-muted">Our Project</h5>
        <h1>Our Recently Completed Projects</h1>
      </div>
      <div class="row g-5">
        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
          <div class="project-item">
            <div class="project-img">
              <img src="/img/projects/greenfield.JPG" class="img-fluid w-100 rounded" alt="">
              <div class="project-content">
                <a href="https://greenfieldagricgh.com/" target="_blank" class="text-center">
                  <h4 class="text-secondary">Web design</h4>
                  <p class="m-0 text-white">Website For Greenfield</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
          <div class="project-item">
            <div class="project-img">
              <img src="/img/projects/eazysch.webp" class="img-fluid w-100 rounded" alt="">
              <div class="project-content">
                <a href="https://www.eazysch.com" class="text-center" target="_blank">
                  <h4 class="text-secondary">Web Development</h4>
                  <p class="m-0 text-white">School Management App</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="project-item">
            <div class="project-img">
              <img src="/img/projects/roseford.webp" class="img-fluid w-100 rounded" alt="">
              <div class="project-content">
                <a href="https://rosefordadvocates.com/" class="text-center" target="_blank">
                  <h4 class="text-secondary">Web Development</h4>
                  <p class="m-0 text-white">A Law firm</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
          <div class="project-item">
            <div class="project-img">
              <img src="/img/projects/easysales.webp" class="img-fluid w-100 rounded" alt="">
              <div class="project-content">
                <a href="https://www.myeasysalesapp.com/" target="_blank" class="text-center">
                  <h4 class="text-secondary">Web Development</h4>
                  <p class="m-0 text-white">Sales App</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Project End -->

</template>

<style scoped>

</style>