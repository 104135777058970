<script setup>

</script>

<template>
  <!-- Services Start -->
  <div class="container-fluid services py-5 my-5">
    <div class="container py-5">
      <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style="max-width: 600px;">
        <h5 class="text-muted">Our Services</h5>
        <h1>Services Built Specifically For Your Business</h1>
      </div>
      <div class="row g-5 services-inner">
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/wd.png" alt="cctv" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">Web Design</h4>
                <p class="mb-4">
                  In the case of responsive design, we make sure all contents and functionalities
                  are the same for every device.
                   Therefore, a large-screen desktop and smartphone browser displays
                  the same content. The only difference is in the layout of the content.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/dev.png" alt="wd" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">Web Development</h4>
                <p class="mb-4">
                  Stay ahead of the curve with our use of the latest web development
                  technologies. Whether it's responsive design, progressive web apps, or seamless
                  integrations, we leverage the tools and frameworks that guarantee a modern,
                  efficient, and scalable solution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/ux.png" alt="ux" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">UI/UX Design</h4>
                <p class="mb-4">
                  Our design philosophy revolves around your users. We conduct in-depth
                  research to understand their behaviors, preferences, and pain points.
                  By putting your audience at the center of our design process, we create
                  interfaces that are not just visually stunning but also intuitive and user-friendly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/networking.png" alt="networking" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">Computer Networking</h4>
                <p class="mb-4">
                  We understand that every business has unique requirements. Our experts work
                  closely with you to design a customized network architecture
                  that aligns with your current needs and future growth plans. From small-scale
                  setups to complex enterprise solutions, we've got you covered.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/gd.png" alt="gd" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">Graphic Designing</h4>
                <p class="mb-4">
                  Whether you need a sleek corporate presentation, eye-catching social
                  media graphics, or a complete brand overhaul, we've got the
                  versatility to meet your diverse design needs. Our expertise
                  spans across various industries and design styles.
                </p>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <img src="/img/services/cctv.png" alt="cctv" class="fa-7x mb-4 img-fluid">
                <h4 class="mb-3">CCTV Installation</h4>
                <p class="mb-4">
                  No two security requirements are the same. Our CCTV installation
                  services are personalized to your specific needs.
                  We conduct thorough site assessments to design a system
                  that provides optimal coverage, ensuring the security
                  of your property is never compromised.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- Services End -->

</template>

<style scoped>

</style>